<template>
	<div class="container">
        <calculator-merchandise-price></calculator-merchandise-price>

		<calculator-break-even-fuel-price></calculator-break-even-fuel-price>

        <calculator-fuel-price></calculator-fuel-price>
		<div class="veecli-spacer-row"></div> 
        <b-message class="is-info">
            These calculators are for informational purposes only and should not be used to prepare official tax returns or any other government documents. VEECLi does not guarantee the accuracy of the calculated amounts, which should not be relied upon when determining payments owed to federal, state, or local tax authorities.
        </b-message>  
	</div>
</template>

<script>

import CalculatorMerchandisePrice from "../../../components/app/CalculatorMerchandisePrice"
import CalculatorBreakEvenFuelPrice from "../../../components/app/CalculatorBreakEvenFuelPrice"
import CalculatorFuelPrice from "../../../components/app/CalculatorFuelPrice"

export default {
	components: {
		CalculatorMerchandisePrice,
		CalculatorBreakEvenFuelPrice,
		CalculatorFuelPrice
	}
}
</script>